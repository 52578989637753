
.MailerContainer {
    background-color: #fff;
    min-height: calc(100vh - 70px);
    max-height: calc(100vh - 70px);
    min-width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.MailerLeftContainer {
    min-width: 15vw;
    max-width: 15vw;
    border-right: 1px solid #e7e7e7;
    min-height: calc(100vh - 70px);
    max-height: calc(100vh - 70px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
}

.MailerLeftNavigationContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    width: 100%
}




.MailerLeftItemContainer {
    margin: 7px;
    padding: 15px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.5s;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border: 1px solid #e7e7e7;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    width: 80%;
}

.MailerLeftItemContainerSelected {
    margin: 7px;
    padding: 15px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: space-between;
    background-color: #e3e3e3;
    font-weight: 700;
    border: 1px solid #e7e7e7;
    font-size: 15px;
    width: 80%;
}

.ChatGPTChat:hover {
    cursor: pointer;
    background-color: #f3f3f3;
    transition: background-color 0.3s, box-shadow 0.5s;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);

}


.MailerRightContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 85vw;
    max-width: 85vw;
    min-height: calc(100vh - 70px);
    max-height: calc(100vh - 70px);
    overflow: auto;
}

.MailerLeftFiltersContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 50px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    padding: 0px 20px 20px 20px;
    border-radius: 20px;
    border: 1px solid #e7e7e7;
    transition: all 0.5s;
    color: #fedbc8c9
}

.MailerFiltersTitle {
    display: block;
    color: #b1b1b1;
    font-weight: 600;
    padding: 10px;
    font-size: 14px;


}

.MailerDatePickerContainer {
    padding-top: 25px;
}


.MailerContentContainer {
    width: calc(100% - 0px)
}

.MailerMailingsTableTabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #e7e7e7;
    padding: 5px 0px 5px 0px;
    position: relative;
}

.MailerMailingsTableTab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 20px 5px 20px;
    cursor: pointer;
    user-select: none;
    border-radius: 0px;
    border: 1px solid #fff;
    transition: all 0.5s;
    font-size: 14px;
    position: relative;
    color: #637381
}

.MailerMailingsTableTabActive {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 20px 5px 20px;
    cursor: pointer;
    user-select: none;
    border-radius: 0px;
    border: 1px solid #fff;
    transition: all 0.5s;
    font-size: 14px;
    position: relative;
    color: #444;
    font-weight: 600;

}

.MailerMailingsTableTabActive:after {
    content: '';
    position: absolute;
    left: 28%;
    bottom: -8px; 
    width: 80px;
    height: 3px; 
    background-color: #000; 
    margin-left: -10px;
    margin-top: -10px;
    border-radius: 20px;
  }



.MailerMailingsTableTabIcon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    user-select: none;
    
}

.MailerMailingsTableTabIconContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    border: 1px solid #e7e7e7;
    padding: 7px;
    color: #666;
}

.MailerMailingsTableTabIconSpinning {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    border: 1px solid #e7e7e7;
    padding: 7px;
    color: #666;
    animation: spin 0.5s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}



.MailerMailingsTableTabText {
    display: block;
    margin-right: 6px;
}

.MailerMailingsTableTabAll {
    display: block;
    background-color: rgba(0, 0, 0, 0.79);
    width: fit-content;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-size: 12px;

}

.MailerMailingsTableTabProcessed {
    display: block;
    background-color: #99e099c9;
    width: fit-content;
    text-align: center;
    padding: 5px 7px 5px 7px;
    border-radius: 20px;
    color: #008000;
    font-weight: 600;
    font-size: 12px;

}


.MailerMailingsTableTabSelecting {
    display: block;
    background-color: rgb(72, 0, 173);
    width: 100px;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    font-size: 12px;

}

.MailerMailingsTableTabSending {
    display: block;
    background-color: #fee3c8c9;
    width: fit-content;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 40px;
    color: #e99701;
    font-weight: 600;
    font-size: 12px;

}

.MailerMailingsTableTabGenerating {
    display: block;
    background-color: #c8defec9;
    width: fit-content;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    color: #024fa7;
    font-weight: 600;
    font-size: 12px;

}


.MailerMailingsTableTabScheduled {
    display: block;
    background-color: #e5e5e5c9;
    width: fit-content;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    color: #888;
    font-weight: 600;
    font-size: 12px;

}

.MailerMailingsTableTabPaused {
    display: block;
    background-color: #fedbc8c9;
    width: fit-content;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    color: #E95D01;
    font-weight: 600;
    font-size: 12px;
}

.MailerMailingsTableTabCanceled {
    display: block;
    background-color: #fcd3d3c9;
    width: fit-content;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    color: #b33535;
    font-weight: 600;
    font-size: 12px;
}



.MailerMailingsTable {
    border-collapse: collapse;
    width: 100%;
}

.MailerMailingsTable > tbody > tr > td {
    border-bottom: 1px solid #e7e7e7;
    padding: 5px 0px 5px 0px;
}


.MailerMailingsTable > tbody > tr:hover {
    background-color: #f8f8f8;
}

.MailerMailingsTableSelectedRow {
    background-color: #c8fad6 !important;
    color: #00a76f;
    font-weight: 600;
}

.MailerSelectedMailingCheckboxContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: fit-content;
    color: #fff;
    border-radius: 45px;
    cursor: pointer;
    margin-left: 5px;
    transition: all 0.5s;
}

.MailerSelectedMailingCheckboxContainer:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: fit-content;
    color: #fff;
    border-radius: 45px;
    background-color: #ededed;
}

.MailerSelectedMailingCheckbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1.5px solid #b6b6b6;
    width: 15px;
    height: 15px;
    color: #ffffff00;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
}

.MailerSelectedMailingCheckboxChecked {
    background-color: #00a76f;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1.5px solid #00a76f;
    width: 15px;
    height: 15px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
}

.MailerSelectedMailingResendButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 40px;
}

.MailerSelectedMailingResendButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.5s;
}

.MailerSelectedMailingResendButtonText {
    display: block;
    padding-right: 15px;
    user-select: none;
}

.MailerSelectedMailingResendButton:hover {
    background-color: #00a76f;
    color: #fff;
    
}


.MailerMailingColumn1 {
    padding: 5px 0px 5px 0px;
}


.MailerMailingRowMailingName {
    display: block;
    font-size: 14px;
    color: #222;

}

.MailerMailingRowDescription {
    display: block;
    font-size: 13px;
    color: #888;
    padding: 5px 0px 5px 0px;
}


.MailerMailingColumn2 {
    padding: 5px 0px 5px 0px;
}


.MailerMailingRowMailingStartDate {
    display: block;
    font-size: 14px;
    color: #222;


}

.MailerMailingRowMailingStartTime {
    display: block;
    font-size: 13px;
    color: #888;
    padding: 5px 0px 5px 0px;
}


.MailerMailingRowStatusProcessed {
    display: block;
    background-color: rgba(153, 224, 153, 0.79);
    width: fit-content;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    color: green;
    font-weight: 600;
    font-size: 12px;

}


.MailerMailingRowStatusSelecting {
    display: block;
    background-color: rgba(200, 222, 254, 0.79);
    width: fit-content;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    color: rgb(2, 79, 167);
    font-weight: 600;
    font-size: 12px;

}

.MailerMailingRowStatusSending {
    display: block;
    background-color: rgba(254, 227, 200, 0.79);
    width: fit-content;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    color: rgb(233, 151, 1);
    font-weight: 600;
    font-size: 12px;

}

.MailerMailingRowStatusScheduled {
    display: block;
    background-color: rgba(229, 229, 229, 0.79);
    width: fit-content;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    color: #888;
    font-weight: 600;
    font-size: 12px;

}


.MailerMailingRowStatusPaused {
    display: block;
    background-color: #fedbc8c9;
    width: fit-content;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    color: #E95D01;
    font-weight: 600;
    font-size: 12px;
}


.MailerMailingRowStatusCanceled {
    display: block;
    background-color: rgba(252, 211, 211, 0.79);
    width: fit-content;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    color: rgb(179, 53, 53);
    font-weight: 600;
    font-size: 12px;
}

.MailerTableButtons {
    min-width: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
}


.MailerMailingRowStopMailingButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.MailerMailingRowStopMailingButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    border: 1px solid #e7e7e7;
    cursor: pointer;
    border-radius: 30px;
    color: #666;
    margin: 5px;
}




.MailerMailingRowMailingID {
    display: block;

}

.MailerMailingRowBodyFile {
    display: block;
    font-size: 14px;
    color: #222;


}

.MailerMailingRowState {
    display: block;

}

.MailerMailingRowSuccess {
    display: block;
    font-size: 14px;
    color: #222;


}

.MailerMailingRowTotal {
    display: block;
    font-size: 14px;
    color: #222;


}



.MailerMailingRowViewed {
    display: block;
    font-size: 14px;
    color: #222;

}

.MailerMailingRowClicked {
    display: block;
    font-size: 14px;
    color: #222;

}






.MailerAccountsTable {
    width: 100%
}

.MailerAccountsTableRow {

}

.MailerAccountsLinkAccountButton {
    display: block;
    border: 1px solid #e7e7e7;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    width: fit-content;
    font-size: 14px;
    color: white;
    background-color: #272727;
    font-weight: 600;
    cursor: pointer;
}

.MailerAccountsFixedFromButton {
    display: block;
    border: 1px solid #e7e7e7;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    width: fit-content;
    font-size: 14px;
    color: #666;
    background-color: #e7e7e7;
    font-weight: 600;
    cursor: pointer;

}


.MailerChartIconContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    border: 1px solid #e7e7e7;
    cursor: pointer;
    border-radius: 30px;
    color: #000
}


.MailerSeedTestContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 60px;
    overflow: none;
}

.MailerSeedTestTable {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 20px;
    border: 1px solid #e7e7e7;
    width: 800px
}

.MailerSeedTestTableHeaderRow {
    font-weight: 600;
}

.MailerSeedTestUserName {
    display: block;
    margin-left: 10px;
}

.MailerSeedTestUserSettings {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #999;
    border: 1px solid #e7e7e7;
    padding: 5px;
    border-radius: 30px;
    height: 25px;
    width: 25px;
    margin-left: 20px;
    user-select: none;
    cursor: pointer;
}


.MailerAdminSettingsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.MailerAdminSettingsCreativesContainer {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 20px;
    border: 1px solid #e7e7e7;
    width: 800px;
    margin-top: 40px;
}

.MailerAdminSettingsTitle {
    text-transform: uppercase;
    display: block;
    color: #999;
    text-align: center;
    width: 100%;
    font-weight: 600;
}

.MailerAdminSettingsSaveButton {
    margin: 20px;
    padding: 15px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    display: block;
    text-align: center;
    background-color: #272727;
    color: #fff;
    

}


.MailerChartPopUpContainer {
    margin-top: 150px;
    min-height: 300px;
    max-height: 300px;
    min-width: 700px;
    max-width: 700px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0px;
    border-radius: 30px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin: auto;
    outline: none;
    overflow: auto;
}

.MailerChartPopUpContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.MailerSeedTestSelect {
    display: block;
    width: 400px;
    margin: auto;
    font-size: 16px;
    height: 40px;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    margin-top: 20px;
}

.MailerDeletePopupConfirmation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.MailerDeletePopupConfirmationButtonYes {
    margin: 20px;
    display: block;
    background-color: #272727;
    padding: 5px 10px 5px 10px;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
}

.MailerDeletePopupConfirmationButtonNo {
    margin: 20px;
    display: block;
    background-color: #fff;
    padding: 5px 10px 5px 10px;
    color: #272727;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
}






.MailerResendMailingPopUpContainer {
    margin-top: 0px;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 400px;
    max-width: 400px;
    background: rgb(238, 196, 174);
    background: linear-gradient(45deg, rgba(238, 196, 174, 0.1) 0%, rgba(148, 233, 200, 0.1) 100%);
    backdrop-filter: blur(55px);
    position: fixed; /* changed to fixed */
    top: 0;
    right: -700px; /* initially hide it off-screen */
    padding: 0;
    border-radius: 0;
    z-index: 1000;
    outline: none;
    overflow: none;
    transition: right 0.3s ease; /* added transition */
}

.MailerResendMailingPopUpContainer.open {
    right: 0; /* slide it in when open */
    box-shadow: 0px 0px 100px rgba(110, 110, 110, 0.5);

}


.MailerResendMailingContainerHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d5d5d595;
    min-height: 60px;
}

.MailerResendMailingHeaderText {
    display: block;
    font-size: 16px;
}

.MailerResendMailingHeaderIcon {
    margin: 0px 10px 0px 10px;
    height: 40px;
    width: 40px;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 0px solid #666;
    transition: all 0.5s;
}

.MailerResendMailingHeaderIcon:hover {
    background-color: #78787829;
}

.MailerResendMailingContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    min-height: calc(100vh - 61px);
    max-height: calc(100vh - 61px);
    overflow: auto;
}

.MailerResendMailingEstimatedTimeRemainingText {
    display: block;
    border-bottom:  1px solid #d5d5d595;
    color: #666;
    font-weight: 600;
    width: 100%;
    text-align: center;
    padding: 4px;
}

.MailerResendMailingRow {
    width: calc(100% - 20px);
    border-bottom: 1px solid #d5d5d595;
    position: relative;
    padding: 10px;
}

.MailerResendMailingAvatar {
    position: absolute;
    top: 10px;
    left: 20px;
}

.MailerResendMailingDeleteIcon {
    display: none;
    position: absolute;
    top: 10px;
    left: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    padding: 8px;
    cursor: pointer;
    border-radius: 20px;
    background-color: #808080d3
}

.MailerResendMailingAvatarContainer:hover .MailerResendMailingDeleteIcon {
    display: flex;

}



.MailerResendMailingPreviousMailID {
    display: block;
    text-align: center;
    font-weight: 600;
}

.MailerResendMailingPreviousMailName {
    display: block;
    text-align: center;

}

.MailerResendMailingPreviousAccount {
    display: block;
    text-align: center;
    font-size: 14px;
    color: #666;
}

.MailerResendMailingPreviousStartTimeContainer {
    position: absolute;
    top: 10px;
    right: 20px;
}

.MailerResendMailingPreviousStartDate {
    display: block;
    text-align: right;
    font-size: 14px;
}

.MailerResendMailingPreviousStartTime {
    display: block;
    text-align: right;
    font-size: 14px;
    color: #666;
    padding-top: 2px;
}

.MailerResendMailingLoadingContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.MailerResendMailingProgressParent {
    width: 80%;
    height: 10px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0);
}

.MailerResendMailingProgressChild {
    height: 10px;
    background-color: #1dca23;
    border-radius: 10px;
    transition: all 0.3s
    
}

.MailerResendMailingInfo {
    border: 1px dashed #cecece;
    padding: 10px;
    border-radius: 10px;
    margin: 0px 20px 0px 60px;
    color: #666;
    position: relative;
}

.MailerResendMailingInfoIcon {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.MailerResendMailingSendIcon {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    cursor: pointer;
}

.MailerResendMailingSendIcon:hover {
    background-color: #78787829;

}

.MailerResendMailingSendNowText {
    display: block;
    font-size: 15px;
    padding: 2px 8px 2px 8px;
}



.MailerResendMailingStartDateSelectorRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.MailerResendMailingStartDateSelectionSaveIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    padding: 5px;
    border-radius: 30px;
    cursor: pointer;
}

.MailerResendMailingStartDateSelectionSaveIcon:hover {
    background-color: #78787829;

}

.MailerResendMailingStartDateHoverContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    padding: 2px 8px 2px 8px;
    border: 1px solid #00000000;
    border-radius: 10px;
    cursor: pointer;
    width: fit-content;
    transition: all 0.3s;
}

.MailerResendMailingStartDateHoverContainer:hover {
    border: 1px solid #939393;
}

.MailerResendMailingStartDate {
    display: block;
    font-size: 15px;
}

.MailerResendMailingStartDateEditIcon {
    display: block;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none; /* Prevents interaction when hidden */
    height: 20px;
    width: 20px;
    margin-left: 10px;
}

.MailerResendMailingStartDateHoverContainer:hover .MailerResendMailingStartDateEditIcon {
    opacity: 1;
    pointer-events:all; /* Prevents interaction when hidden */
    height: 20px;
    width: 20px;
}





.MailerResendMailingListSectionContainer {
    position: relative;
    width: 80%
}

.MailerResendMailingListContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    padding: 2px 8px 2px 8px;
    border: 1px solid #00000000;
    border-radius: 10px;
    cursor: pointer;
    width: fit-content;
    transition: all 0.3s;
}

.MailerResendMailingListContainer:hover {
    border: 1px solid #939393;
}

.MailerResendMailingList {
    display: block;
    padding-right: 10px;
    user-select: none;
}

.MailerResendMailingListArrow {
    display: block;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none; /* Prevents interaction when hidden */
    height: 20px;
    width: 20px;

}

.MailerResendMailingListContainer:hover .MailerResendMailingListArrow {
    opacity: 1;
    pointer-events:all; /* Prevents interaction when hidden */
}

.MailerResendMailingListDropdown {
    position: absolute;
    top: 25px;
    left: 0px;

    background-color: #fffffffb;

    border-radius: 10px;
    border: 1px solid #dedede;
    padding: 5px 0px 5px 0px;
    width: calc(100% - 12px);
    max-height: 400px;
    z-index: 100;
    overflow-y: auto;
    transition: all 0.5s;
}

.MailerResendMailingListDropdownRow {
    display: block;
    padding: 2px 2px 2px 8px;
    cursor: pointer;
    user-select: none;
}

.MailerResendMailingListDropdownRow:hover {
    background-color: #e4e4e4;
}

.MailerResendMailingListRangeEditRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.MailerResendMailingListRangeEditInput {
    max-width: 70px;
    background-color: #00000000;
    border: 1px solid #666;
    border-radius: 5px;
    color: #444;
    font-size: 15px;

}

.MailerResendMailingListRangeEditTotal {
    display: block;
    font-size: 15px;

}

.MailerResendMailingListRangeEditSaveIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 30px;
}

.MailerResendMailingListRangeEditSaveIcon:hover {
    background-color: #dcdcdc
} 

.MailerResendMailingListRange {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    padding: 2px 8px 2px 8px;
    border: 1px solid #00000000;
    border-radius: 10px;
    cursor: pointer;
    width: fit-content;
    transition: all 0.3s;
}

.MailerResendMailingListRange:hover {
    border: 1px solid #939393;
}

.MailerResendMailingListRangeText {
    display: block;
    padding-right: 15px;
}

.MailerResendMailingListRangeIcon {
    display: block;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none; /* Prevents interaction when hidden */


}

.MailerResendMailingListRange:hover .MailerResendMailingListRangeIcon {
    opacity: 1;
    pointer-events:all; /* Prevents interaction when hidden */
    height: 20px;
    width: 20px;
}

.MailerResendMailingsCalculateIndividualButton {
    display: block;
    font-size: 15px;
    color: #888;
    border: 1px solid #888;
    width: fit-content;
    padding: 3px 10px 3px 10px;
    border-radius: 10px;
    user-select: none;
    cursor: pointer;
    margin: auto;
}

.MailerResendMailingsSubmitButton {
    position:sticky;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #272727;
    padding: 10px 20px 10px 20px;
    border-radius: 20px;
    color: white;
    user-select: none;
    cursor: pointer;
    
}